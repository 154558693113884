@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  /* padding-bottom: 90px; */

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  font-family: var(--fontFamily);
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
  font-family: var(--fontFamily);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;
  font-family: var(--fontFamily);

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--colorGrey100);
  font-family: var(--fontFamily);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 30px 24px 24px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin: 0 24px;
  padding: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breadcrumbsItem {
  display: flex;
  align-items: center;
  margin: 0 12px 0 0;
  padding: 0;
  font-size: 10px;
  font-weight: var(--fontWeightRegular);
  line-height: 25px;
  color: #8F8F90;
  cursor: pointer;
  word-break: break-word;

  @media (--viewportMedium) {
    font-size: 12px;
  }

  &:after {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 4px;
    transform: translateY(-2px);
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.58398 3.5L7.58398 6.5L4.58398 9.5' stroke='%23201F22' stroke-width='0.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }

  &:last-child::after {
    display: none;
  }
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.topColumnForProductLayout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.mainColumnForProductLayout {
  margin-top: 40px;

  @media (--viewportLarge) {
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.galleryColumnForProductLayout {
  position: relative;
  
  @media (--viewportLarge) {
    flex-basis: 561px;
    max-width: 561px;
  }

  @media (--viewport1280) {
    flex-shrink: 0;
  }
}

.orderColumnForProductLayout {

  @media (--viewportLarge) {
    display: block;
    margin-left: 36px;
    flex-basis: 424px;
  }
  
  @media (--viewport1280) {
    flex-shrink: 0;
    margin-left: 115px;
  }

  & .breadcrumbs {
    display: none;

    @media (--viewportLarge) {
      display: flex;
    }
  }
}

.orderColumnStickyWrapper {

  @media (--viewportMedium) {
    position: sticky;
    top: 124px;
  }
}

.contentWrapperForProductLayout {
  padding: 48px 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 48px 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    max-width: unset;
    width: min(calc(var(--contentMaxWidth) + 118px), 100vw);
    padding: 78px 36px 0 36px;
    margin: 0 auto 117px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 20px;
  }
  /* Note: panel height might be too much on small viewport - hence min-height */
  /* @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  } */
}

.productOrderPanelMobile {

}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 34px;
  line-height: 38px;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}


.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  color: var(--colorGrey700);
  font-weight: var(--fontWeightMedium);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightMedium);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMultiEnum {
  margin-bottom: 0;
}

.sectionDetails {
  padding: 24px 0;
  border-top: 1px solid var(--customLightGray);
  border-bottom: 1px solid var(--customLightGray);

  @media (--viewportMedium) {
    padding: 50px 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 24px;
  margin-top: 42px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileBreadcrumbs {
  
  @media (--viewportLarge) {
    display: none;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.tabs {

}

.tabsNav {
  display: flex;
  border-bottom: 1px solid #D2D2D3;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.tabsNavItem {
  padding: 12px 0;
  margin-right: 12px;
  color: #8F8F90;
  transform: translateY(1px);
  cursor: pointer;
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  
  @media (--viewportMedium) {
    padding: 13px 23px;
    font-size: 18px;
    margin-right: 23px;
  }
}

.tabsNavItemActive {
  border-bottom: 2px solid var(--colorGrey700);
  color: var(--colorGrey700);
  font-weight: var(--fontWeightMedium);
}

.carouselRoot {
  max-width: 100vw;
  overflow: hidden;
  
  margin-bottom: 24px;
  padding: 24px 24px;
  
  @media (--viewportMedium) {
    max-width: calc(100vw - 48px);
    padding: 60px 0 0;
    margin-bottom: 90px;
  }

  @media (--viewportLarge) {
    max-width: 100vw;
  }
}

.carouselTitle {
  padding: 0;
  margin: 0 0 18px;
  font-family: var(--fontFamily);
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  
  @media (--viewportMedium) {
    margin: 0 0 38px;
    font-size: 26px;
    line-height: 38px;
  }
}

.carouselWrapper {
  
  @media (--viewportMedium) {
    margin: 0 -12px;
  }

  & :global(.alice-carousel) {
    position: relative;
  }

  & :global(.alice-carousel__prev-btn),
  & :global(.alice-carousel__next-btn) {
    padding-top: 24px;
  }

  @media (--viewportMedium) {
    & :global(.alice-carousel__prev-btn),
    & :global(.alice-carousel__next-btn) {
      position: absolute;
      top: -78px;
      right: 12px;
  
      padding: 0;
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      width: auto;
    }
  
    & :global(.alice-carousel__prev-btn) {
      transform: translateX(-60px);
    }
  }

}

.carouselCard {
  margin: 0 0 12px;
  
  @media (--viewportMedium) {
    padding: 0 12px;
  }

  @media (--viewportLarge) {
    min-width: 278px;
    max-width: 278px;
  }
}

.author {
  composes: p from global;
  margin: 0 24px 22px;
  padding: 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    color: var(--colorGrey700);
    margin: 0 0 22px;
  }
}

.providerAvatar {
  display: flex;
  margin-right: 10px;
  width: 45px;
  height: 45px;
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: inline;

  & a {
    color: var(--colorGrey700);
    font-weight: var(--fontWeightMedium);
  
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.shortDescription {
  padding: 0;
  margin: 0 24px 22px;
  font-size: 14px;
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
  color: #8F8F90;
  word-break: break-word;

  @media (--viewportMedium) {
    margin: 0 0 22px;
  }
}

.inlineButtons {
  display: flex;
  align-items: center;
  margin: 0 24px 22px;


  @media (--viewportMedium) {
    margin: 0 0 22px;
  }
}

.inlineButton {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  color: var(--colorGrey700);
  font-weight: var(--fontWeightMedium);
  font-size: 12px;
  
  @media (--viewportSmall) {
    margin-right: 15px;
    font-size: 14px;
  }
  
  @media (--viewportMedium) {
    margin-right: 20px;
    font-size: 16px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  & svg {
    margin-right: 4px;
  }
}

.variantItem {
  display: flex;
  /* align-items: center; */
  border-bottom: 2px solid var(--marketplaceColor);
  /* padding-bottom: 16px; */
  margin-bottom: 16px;
  cursor: pointer;
}

.variantItemSelected {

  & .customRadiobutton .checked {
    display: block;
  }
}

.variantItemContent {
  width: 100%;
}

.variantItemHeading {
  /* margin-bottom: 20px; */
}

.customRadiobutton {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border: 1px solid var(--colorBlack);
  border-radius: 50px;
  flex-shrink: 0;

  & .checked {
    display: none;
  }
}

.customRadiobuttonSelected {

  & .checked {
    display: block;
  }
}

.variantTitleHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.variantTitle {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: var(--fontFamily);
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
}

.variantPrice {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
}

.variantList {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.variantListItem {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.variantListItemTitle {
  font-weight: 500;
}

.variantOptions {
  padding: 0;
  margin: 10px 0;
}


.variantQuantity {
  display: flex;

  & input::placeholder {
    color: var(--colorGrey700);
  }
}

.variantQuantityMinus,
.variantQuantityPlus {

  & svg {
    border: 1px solid var(--marketplaceColor);
  }
}


.variantQuantityMinus {
  margin-right: 10px;
}

.variantQuantityPlus {
  margin-left: 10px;
}
